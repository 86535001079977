<template>
	<el-card class="box-card">
	  <div slot="header" class="clearfix">
	    <h3 class="inBlock">活动列表</h3>
	  </div>
	  <div style=" padding:0 0 20px 0;">
	  		  <!-- <el-button @click="tab_click(index)" :type="num==index?'primary':''" v-for="(item,index) in tabs">{{item}}</el-button> -->
	  		  <!-- <el-button></el-button>
	  		  <el-button></el-button> -->
	  		  <el-button @click="add_coupon" type="success">添加活动</el-button>
	  </div>
	    <template>
	      <el-table
	        :data="tableData"
			border
	        style="width: 100%">
	        <el-table-column
	          prop="name"
	          label="活动标题"
	          width="180">
	        </el-table-column>
	        <el-table-column
			  prop="start_type"
	          label="活动状态"
	          width="180">
			  <!-- <template slot-scope="scope">
				  {{scope.row.status == 1 ? '已开始' : '未开始'}}
			  </template> -->
	        </el-table-column>
			<el-table-column
	          prop="starttime"
	          label="开始时间">
	        </el-table-column>
			<el-table-column
	          prop="endtime"
	          label="结束时间">
	        </el-table-column>
			<el-table-column
	          prop="addtime"
	          label="添加时间">
	        </el-table-column>
	        <!-- <el-table-column
	          prop="count"
	          label="活动直播间">
	        </el-table-column> -->
			<!-- <el-table-column
			  prop="address"
			  label="活动福利">
			  <template slot-scope="scope">
				<div v-if="scope.row.type==2">
					<span style="margin-left: 6px;" v-for="(item,index) in scope.row.live_lottery_gift" :key="index">{{item.name}}</span>
				</div>
				<div v-else>
					<span style="margin-left: 6px;" v-for="(item,index) in scope.row.live_lottery_gift" :key="index">{{item.name}}</span>
				</div>
			  </template>
			</el-table-column> -->
			<!-- <el-table-column
			  prop="count"
			  label="中奖人数">
			</el-table-column> -->
			<el-table-column label="是否显示">
				<template slot-scope="scope">
				<el-switch
					@change="ishide(scope.row)"
					v-model="scope.row.status"
					:active-value="1"
					:inactive-value="2"
				></el-switch>
				</template>
			</el-table-column>
			<!-- <el-table-column
			  prop="status"
			  label="是否显示">
			</el-table-column> -->
			<el-table-column
			  label="操作">
			  <template slot-scope="scope">
					<el-button @click="handleClick(scope.row.id)" type="text" size="small">删除</el-button>
					<el-button @click="bianji(scope.row.id)" type="text" size="small">编辑</el-button>
					<el-button  @click="$router.push(`./secondsKillGoods?id=${scope.row.id}`)" type="text" size="small">商品管理</el-button>
			  </template>
			</el-table-column>
	      </el-table>
	    </template>
		<el-pagination
		  @current-change="handleCurrentChange"
		  :page-size="tab.limit"
		  layout="prev, pager, next, jumper"
		  :total="total">
		</el-pagination>
	

		<!-- 添加活动 -->
    <el-dialog :title="title" :visible.sync="addDialogVisible" width="40%">
        <el-form
            :model="addFrom"
            ref="liveFrom"
            label-width="100px"
          >
             <el-form-item label="活动标题">
                <el-input v-model="addFrom.name"></el-input>
            </el-form-item>
			 <el-form-item label="活动日期">
                <el-date-picker
					v-model="dateValue"
					type="date"
					placeholder="选择日期"
					value-format="yyyy-MM-dd"
					@change="dateChange">
				</el-date-picker>
            </el-form-item>
			<el-form-item label="时间段">
                  <el-time-picker
					is-range
					v-model="timeValue"
					range-separator="至"
					start-placeholder="开始时间"
					end-placeholder="结束时间"
					placeholder="选择时间范围"
					value-format="HH:mm:ss"
					@change="timeChange">
				</el-time-picker>
            </el-form-item>
            <el-form-item label="是否显示">
              <el-radio v-model="addFrom.status" :label="1">是</el-radio>
              <el-radio v-model="addFrom.status" :label="2">否</el-radio>
            </el-form-item>
          </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="warning" @click="addDialogVisible = false">关闭</el-button>
        <el-button type="primary" @click="submitProSele">确 定</el-button>
      </span>
    </el-dialog>
	</el-card>  
</template>

  <script>
	  import {getSystemsettings} from '../../api/getSystemsetting.js'
	  import {
	    goodsactiveseckillList,
		goodsactiveseckillSave,
		goodsactiveseckillDelete,
		goodsactiveseckillDetails,
		goodsseckillChange,
		updateStatus,
	  } from "@/api/api.js";
    export default {
      data() {
        return {
			dialogVisible:false,
			addDialogVisible:false,
			title: '添加活动',
			imgurl:'',
			tableData: [],
			total:0,
			// 添加活动
			dateValue: '',
			timeValue: '',
			addFrom:{
				id: '', //
				starttime: '', //开始时间
				endtime	: '', //结束时间
				name: '', //名称
				status: 1, //状态 1显示  2隐藏
			},
			// 列表请求数据
			tab:{
				page: 1, //页码
				limit: 10, //条数
				starttime: '',//开始时间
				endtime: '',//结束时间
				name: '',//活动标题
				status: '', //状态 1显示  2隐藏
			},
			rules: [],
        }
      },
	  mounted(){
		  this.getlist()
		//   this.getGoods()
		  getSystemsettings.then(res=>{
		  	this.imgurl=res
		  })
	  },
	  methods:{
		  // 列表
		  async getlist(){
			 const {data}= await goodsactiveseckillList(this.tab)
			 console.log(data)
			 if(data.code!=200){
				 return this.$message.error(data.data);
			 }
			  this.tableData = data.data.list.data
			  this.total = data.data.list.total
		  },
		  
		//   添加活动
		  add_coupon(){
			this.dateValue = ''
			this.timeValue = ''
			this.addFrom.id = '', //
			this.addFrom.starttime = '', //开始时间
			this.addFrom.endtime = '', //结束时间
			this.addFrom.name = '', //名称
			this.addFrom.status = 1, //状态 1显示  2隐藏
			this.addDialogVisible = true
		  },
		//   提交添加活动
		  async submitProSele(){
			 const {data}= await goodsactiveseckillSave(this.addFrom)
			  if(data.code!=200){
				 return this.$message.error(data.data);
			 }else{
				this.$message({
					type: "success",
					message: "操作成功!",
				});
			 }
			this.addDialogVisible = false
		  	this.getlist()
			 console.log(data.data)
		  },
		  //是否显示
			async ishide(row) {

			// row.is_hid = row.is_hid == 1 ? 2 : 1;
			console.log('row====',row)
			const { data } = await goodsseckillChange({
				id: row.id,
			});
			if (data.code != 200) return this.$message.error(data.data);
			this.getlist();
			// row.is_hid = row.is_hid == 1 ? 2 : 1;
			this.$message({
				message: "操作成功",
				type: "success",
			});
			},
		//  活动日期选择
		  dateChange(e){
			  if(e){
					if(this.timeValue){
						this.addFrom.starttime = e + ' ' + this.timeValue[0]
						this.addFrom.endtime = e + ' ' + this.timeValue[1]
					}
			   }
			  console.log(e, this.value1)
		  },
		//   活动时间选择
		   timeChange(e){
			   if(e){
				   if(this.dateValue){
						this.addFrom.starttime = this.dateValue + ' ' + this.timeValue[0]
						this.addFrom.endtime = this.dateValue + ' ' + this.timeValue[1]
					}
			   }
			   
			  console.log(e,this.timeValue)
		  },
		  
		//   删除
		  handleClick(id){
			  this.$confirm('此操作将永久删除该活动, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
				}).then(async () => {
					const {data} =await goodsactiveseckillDelete({id:id})
					if(data.code!=200){
						return this.$message.error(data.data);
					}else{
					this.$message({
						type: 'success',
						message: '删除成功!'
					});
					this.getlist()
					}
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});          
				});
		  },
		  //编辑
		  async bianji(id){
			  this.add_coupon()
			    const {data}=await goodsactiveseckillDetails({id: id})
			  if(data.code!=200){
				   return this.$message.error(data.data);
			  }else{
				  this.dateValue = data.data.starttime.slice(0, 10)
				  this.timeValue = []
				  this.timeValue.push(data.data.starttime.slice(11, 19))
				  this.timeValue.push(data.data.endtime.slice(11, 19))
				  this.addFrom = data.data
				  console.log(this.timeValue)
				  console.log(data)
			  }
		  },
		  // 分页 
		  handleCurrentChange(val){
			  this.tab.page = val
			  this.getlist()
		  },
		  //更改状态
		  switch_click(id,status,index,row){
			  console.log(id,status,index,row)
			  
			  var this_status
			  if(status==1){
			  	this_status=2
			  }else{
			  	this_status=1
			  }
			  row.status=this_status
			  console.log(id,this_status)
			  this.set_status(id,this_status)
		  },
		  async set_status(id,status){
			  
			  let datas={
				  lottery_id:id,
				  status:status
			  }
			  const {data}=await updateStatus(datas)
			  if(data.code!=200){
			  		return this.$message.error(data.data);
			  }
			  this.$message({
				message:data.data,
				type: 'success'
			  });
		  },
	  },
    }
  </script>

<style>
</style>
